<script lang="ts" setup>
import {
	OBannersRefferal,
	OBannersQuest,
	OBannersWeekGame,
	OBannersPresetPackage,
	OBannersBox,
	OBannersFlipTheCoin,
	OBannersScratch,
	OBannersFreeSpin,
	OBannersRace
} from "#components";

import type { KeenSliderOptions, KeenSliderInstance } from "keen-slider";

defineProps<{
	isLoading?: boolean;
}>();

const bannersList: Record<string, unknown> = {
	bannerPresetPackage: OBannersPresetPackage,
	weekGame: OBannersWeekGame,
	referral: OBannersRefferal,
	quest: OBannersQuest,
	magicBox: OBannersBox,
	flipTheCoin: OBannersFlipTheCoin,
	raceBanner: OBannersRace,
	freeSpin: OBannersFreeSpin,
	scratchBanner: OBannersScratch
} as const;

const isGuest = useIsGuest();
const { banners } = useHomePage({ immediate: !isGuest.value });

const interval = ref();
const sliderActiveSlide = ref(0);
const opacities = ref<number[]>([]);

const autoplay = (slider: KeenSliderInstance) => {
	clearInterval(interval.value);
	interval.value = setInterval(() => {
		if (slider.track.details) {
			slider.track.details.maxIdx === slider.track.details.abs ? slider.moveToIdx(0) : slider.next();
		}
	}, 5000);
};

const sliderRef = ref();

const sliderOptions: KeenSliderOptions = {
	loop: true,
	selector: ".fader__slide",
	defaultAnimation: {
		duration: 1000
	},
	created(slider: KeenSliderInstance) {
		autoplay(slider);
	},
	slideChanged(slider: KeenSliderInstance) {
		autoplay(slider);
		sliderActiveSlide.value = slider.track.details.rel;
	},
	detailsChanged: (slider: KeenSliderInstance) => {
		opacities.value = slider?.track?.details?.slides.map((slide) => slide.portion) || [1, 0, 0, 0];
	}
};

if (process.client) {
	watch(
		() => banners.value?.length,
		() => {
			nextTick(() => {
				setTimeout(() => {
					if (sliderRef.value && sliderRef.value.slider) {
						sliderRef.value.slider.update();
						sliderRef.value.slider.moveToIdx(0);
					}
				}, 50);
			});
		}
	);
}

onBeforeUnmount(() => {
	clearInterval(interval.value);
});
</script>
<template>
	<div class="slider__wrapper">
		<LazyASlider ref="sliderRef" fade-mode :options="sliderOptions">
			<template #default>
				<div
					v-for="(banner, index) in banners"
					:key="index"
					:class="['fader__slide', { single: banners.length < 2, hide: sliderActiveSlide !== index }]"
					:style="{ opacity: opacities[index] }"
				>
					<component :is="bannersList[banner?.type]" v-if="banner?.type in bannersList" :banner="banner" />
					<OBannersHero v-else :banner="banner" />
				</div>
			</template>
			<template #dots="{ slider }">
				<div v-if="banners.length > 1" class="pagination">
					<div
						v-for="(_slide, idx) in banners.length"
						:key="idx"
						:class="['pagination-item', { 'is-active': sliderActiveSlide === idx }]"
						@click="slider?.moveToIdx(idx)"
					/>
				</div>
			</template>
		</LazyASlider>
	</div>
</template>
<style scoped lang="scss">
.event-none {
	pointer-events: none;
}
.slider__wrapper {
	height: 751px;
	width: 100%;
	position: relative;

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 60px;
		left: 0;
		bottom: 0;
		background: var(--gombe);
		z-index: 2;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: gutter(3.2);
		height: 232px;
	}

	&:deep {
		.fader {
			height: 100%;

			.app-banner {
				height: 100%;
			}
		}
	}
}
.keen-slider__slide {
	width: 100%;
}

.fader__slide {
	flex-shrink: 0;
	width: 100%;
	position: absolute;
	top: 0;
	height: 100%;
	transform: none !important;

	&.single {
		opacity: 1 !important;
		height: 100%;
	}

	&.hide {
		display: none;
	}

	@include media-breakpoint-down(md) {
		height: 100%;
	}
}

.pagination {
	display: flex;
	justify-content: center;
	gap: gutter(1);
	margin-top: gutter(1);
	padding: 0 gutter(2);

	&-item {
		width: 28px;
		height: 4px;
		border-radius: 12px;
		background: var(--celaya);
		cursor: pointer;

		&.is-active {
			background: var(--сirebon);
		}
	}
}
</style>
